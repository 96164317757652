import axios, {AxiosResponse} from "axios";
import store from "@/Store/Index";

export default class Api {
    get<T>(url: string): Promise<any> {
        return axios.get<T>(url).catch((result: any) => {
            if (result.response && result.response.status === 401) {
                store.dispatch('logout');
                return;
            }

            if (!result.response) {
                // alert(result);
            }

            throw result;
        });
    }

    post<T>(url: string, data: T): Promise<any> {
        return axios.post<T>(url, {
            ...data
        }).catch((result: any) => {
            if (result.response && result.response.status === 401) {
                store.dispatch('logout');
                return;
            }

            if (!result.response) {
                // alert(result);
            }

            throw result;
        });
    }

    put<T>(url: string, data: T): Promise<any> {
        return axios.put<T>(url, {
            ...data
        }).catch((result: any) => {
            if (result.response && result.response.status === 401) {
                store.dispatch('logout');
                return;
            }

            if (!result.response) {
                // alert(result);
            }

            throw result;
        });
    }

    patch<T>(url: string, data: T): Promise<any> {
        return axios.patch<T>(url, {
            ...data
        }).catch((result: any) => {
            if (result.response && result.response.status === 401) {
                store.dispatch('logout');
                return;
            }

            if (!result.response) {
                // alert(result);
            }

            throw result;
        });
    }

    delete<T>(url: string): Promise<any> {
        return axios.delete<T>(url).catch((result: any) => {
            if (result.response && result.response.status === 401) {
                store.dispatch('logout');
                return;
            }

            if (!result.response) {
                // alert(result);
            }

            throw result;
        });
    }
}
