import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import store from '../Index';
import UserResponse from "@/Api/User/Response/UserResponse";
import LoginRequest from "@/Api/User/Request/LoginRequest";
import loginApi from "@/Api/User/LoginApi";
import axios from "axios";
import userApi from "@/Api/User/UserApi";

@Module({
    name: 'user',
    store: store,
    dynamic: true,
    preserveState: localStorage.getItem('applicationStorage') !== null
})
class UserStore extends VuexModule {
    token: string|null = null;
    user: UserResponse|null = null;

    @Mutation
    public setUser(user: UserResponse|null): void {
        this.user = user;
    }

    @Mutation
    public setToken(token: string): void {
        this.token = token;
    }

    @Action({rawError: true})
    async login(login: LoginRequest): Promise<any> {
        return await loginApi.login(login).then((response) => {
            this.context.commit('setToken', response.data.token);
            // @ts-ignore
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;

            userApi.info().then((response) => {
                this.context.commit('setUser', response.data);
            });
        });
    }

    @Action({rawError: true})
    async updateUserInfo(): Promise<any> {
        return await userApi.info().then((response) => {
            this.context.commit('setUser', response.data);
        });
    }

    @Action({rawError: true})
    async logout(): Promise<any> {
        this.context.commit('setToken', null);
        window.location.reload();
    }

    get getUser() {
        return this.user;
    }

    get isAuthenticated() {
        if (this.token !== null && this.getUser !== null) {
            // @ts-ignore
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            window.Echo.private('user.' + this.getUser.id);
        }

        return this.token !== null;
    }
}
export default UserStore;

