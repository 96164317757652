import Vue from "vue";
import dayjs from 'dayjs';

Vue.filter('moment', function (date: string) {
    if (!date) return 'Never'
    return dayjs(date).format('DD-MM-YYYY HH:mm');
});

Vue.filter('bytesToSize', function (bytes: number) {
    const decimals: number = 2;
    if (bytes === 0) return '0';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
});
