import Vue from 'vue'
import VueRouter, {Route} from 'vue-router'
import {getModule} from "vuex-module-decorators";
import UserStore from "@/Store/Modules/UserStore";
import store from "../Store/Index";
import MenuStore from "@/Store/Modules/MenuStore";

Vue.use(VueRouter);

const routes = [
    {
        path: '/login/:data?',
        component: () => import(/* webpackChunkName: "login" */ '@/Views/Login.vue'),
        meta: { guest: true },
    },
    {
        path: '/',
        component: () => import(/* webpackChunkName: "servers" */ '@/Views/Servers/Index.vue'),
        meta: { requiresAuth: true, title: "Dashboard" },
    },
    {
        path: '/servers',
        component: () => import(/* webpackChunkName: "servers" */ '@/Views/Servers/Index.vue'),
        meta: { requiresAuth: true, title: "Servers" },
    },
    {
        path: '/sites',
        component: () => import(/* webpackChunkName: "sites" */ '@/Views/Sites.vue'),
        meta: { requiresAuth: true, title: "Websites" },
    },
    {
        path: '/servers/new',
        component: () => import(/* webpackChunkName: "servers-new" */ '@/Views/Server/New.vue'),
        meta: { requiresAuth: true, title: "New server" },
    },
    {
        path: '/servers/:id',
        component: () => import(/* webpackChunkName: "server-manage" */ '@/Views/Server/Index.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "server-index" */ '@/Views/Server/Sites.vue'),
            },
            {
                path: 'php',
                component: () => import(/* webpackChunkName: "server-php" */ '@/Views/Server/PHP.vue'),
            },
            {
                path: 'database',
                component: () => import(/* webpackChunkName: "server-database" */ '@/Views/Server/Database.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import(/* webpackChunkName: "server-index" */ '@/Views/Server/Database/Databases.vue'),
                    },
                    {
                        path: 'users',
                        component: () => import(/* webpackChunkName: "server-index" */ '@/Views/Server/Database/Users.vue'),
                    },
                ],
            },
            {
                path: 'cronjobs',
                component: () => import(/* webpackChunkName: "server-cronjobs" */ '@/Views/Server/Cronjobs.vue'),
            },
            {
                path: 'ssh-keys',
                component: () => import(/* webpackChunkName: "server-ssh" */ '@/Views/Server/SSHKeys.vue'),
            },
            {
                path: 'firewall',
                component: () => import(/* webpackChunkName: "server-firewall" */ '@/Views/Server/Firewall.vue'),
            },
            {
                path: 'docker',
                component: () => import(/* webpackChunkName: "server-docker" */ '@/Views/Server/Docker.vue'),
            },
            {
                path: 'supervisor',
                component: () => import(/* webpackChunkName: "server-supervisor" */ '@/Views/Server/Supervisor.vue'),
            },
            {
                path: 'services',
                component: () => import(/* webpackChunkName: "server-services" */ '@/Views/Server/Services.vue'),
            },
            {
                path: 'settings',
                component: () => import(/* webpackChunkName: "server-settings" */ '@/Views/Server/Settings.vue'),
            }
        ],
        meta: { requiresAuth: true },
    },
    {
        path: '/sites/:id',
        component: () => import(/* webpackChunkName: "server-manage" */ '@/Views/Site/Index.vue'),
        children: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "server-index" */ '@/Views/Site/Dashboard.vue'),
            },
            {
                path: 'settings',
                component: () => import(/* webpackChunkName: "server-index" */ '@/Views/Site/Settings.vue'),
            },
        ],
        meta: { requiresAuth: true },
    },
]

const router = new VueRouter({
    routes
});


const userStore: UserStore = getModule(UserStore, store);
router.beforeEach((to, from, next) => {
    const defaultUserPage: string = '/';

    if (to.path === '/' && !userStore.isAuthenticated) {
        return next('/login');
    }

    if (to.matched.length === 0) {
        return next('/');
    }

    return to.matched.some((record) => {
        if (record.meta.requiresAuth === true && !userStore.isAuthenticated) {
            return next('/login');
        }

        if (record.meta.guest === true && userStore.isAuthenticated) {
            return next(defaultUserPage);
        }

        if (record.meta.permission !== undefined && userStore.isAuthenticated) {
            // if (userStore.user?.plan[record.meta.permission] === 0) {
            //     next('/settings');
            // }
        }

        return next();
    });
});

router.afterEach(async (to: Route) => {
    Vue.nextTick(() => {
        if (to.meta?.title !== undefined) {
            document.title = to.meta.title  + " · " + process.env.VUE_APP_TITLE;
        }
    });
});

export default router;
