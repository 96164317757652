import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import store from '../Index';
import ServerResponse from "@/Api/Server/Response/ServerResponse";

interface ButtonInterface {
    name: string,
    path?: string,
    modal?: boolean,
    color?: string,
    icon?: string,
}

@Module({
    name: 'server',
    store: store,
    dynamic: true
})
class ServerStore extends VuexModule {
    server: ServerResponse = {
        default_php: null,
        install_state: null,
        created_at: "",
        id: "",
        ip_address: "",
        name: "",
        port: 0,
        server_os: "",
        status: 0,
        uptime: ""
    };

    @Mutation
    updateServer(server: ServerResponse): void {
        this.server = server;
    }

    @Action
    setServer(server: ServerResponse) {
        this.context.commit('updateServer', server);
    }

    get getServer(): ServerResponse {
        return this.server;
    }
}
export default ServerStore;

