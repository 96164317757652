import UserResponse from "@/Api/User/Response/UserResponse";
import Api from "@/Api/Api";
import RevokeRequest from "@/Api/User/Request/RevokeRequest";

class UserApi extends Api {
    info(): Promise<any> {
        return this.get<UserResponse>("/user");
    }

    revokeToken(token: string): Promise<any> {
        return this.post<RevokeRequest>('/login/revoke_token', {
            token: token
        });
    }
}

export default new UserApi();
