import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import {i18n} from "@/Plugins/i18n";
import '@mdi/font/css/materialdesignicons.css';
import '@/Assets/App.scss';

Vue.use(Vuetify);

export default new Vuetify({
    theme: { dark: true },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    lang: {
        // @ts-ignore
        t: (key, ...params) => i18n.t(key, params),
    },
})
