import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import Vue from "vue";
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach((rule: string) => {
    extend(rule,
        {
            // @ts-ignore
            ...rules[rule],
            // @ts-ignore
            message: messages[rule]
        });
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
