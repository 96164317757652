import Echo from "laravel-echo";
import Pusher from "pusher-js";
import axios from "axios";

declare global {
    interface Window {
        Echo: Echo;
        Pusher: any;
    }
}

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_WEBSOCKET_KEY,
    wsHost: process.env.VUE_APP_WEBSOCKET_URL,
    wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
    forceTLS: false,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel: any, options: any) => {
        return {
            authorize: (socketId: any, callback: any) => {
                axios.post('/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                    .then(response => {
                        callback(false, response.data)
                    })
                    .catch(error => {
                        callback(true, error)
                    })
            }
        }
    },
});
