import {VuexModule, Module, Mutation, Action} from 'vuex-module-decorators';
import store from '../Index';

interface ButtonInterface {
    name: string,
    path?: string,
    modal?: string,
    color?: string,
    icon?: string,
}

@Module({
    name: 'menu',
    store: store,
    dynamic: true
})
class MenuStore extends VuexModule {
    title: string|null = null;
    subTitle: string|null = null;
    buttons: ButtonInterface[]|null = null;
    currentModal: string|null = null;

    @Mutation
    updateTitle(title: string|null): void {
        this.title = title;
    }

    @Mutation
    updateSubtitle(subTitle: string|null): void {
        this.subTitle = subTitle;
    }

    @Mutation
    updateButtons(button: ButtonInterface[]|null): void {
        this.buttons = button;
    }

    @Mutation
    updateModal(modalName: string|null): void {
        this.currentModal = modalName;
    }

    @Action
    addTitle(title: string) {
        document.title = title + " · " + process.env.VUE_APP_TITLE;
        this.context.commit('updateTitle', title);
    }

    @Action
    addSubtitle(subTitle: string) {
        this.context.commit('updateSubtitle', subTitle);
    }

    @Action
    addButtons(buttons: ButtonInterface[]) {
        this.context.commit('updateButtons', buttons);
    }

    @Action
    openModal(modalName: string) {
        this.context.commit('updateModal', modalName);
    }

    @Action
    closeModal() {
        this.context.commit('updateModal', null);
    }

    @Action
    clear() {
        this.context.commit('updateTitle', null);
        this.context.commit('updateSubtitle', null);
        this.context.commit('updateButtons', null);
        this.context.commit('updateModal', false);
    }

    get getTitle(): string|null {
        return this.title;
    }

    get getSubtitle(): string|null {
        return this.subTitle;
    }

    get getButtons(): ButtonInterface[]|null {
        return this.buttons;
    }

    get getCurrentModal(): string|null {
        return this.currentModal;
    }
}
export default MenuStore;

