import LoginRequest from "@/Api/User/Request/LoginRequest";
import Api from "@/Api/Api";
import LoginResponse from "@/Api/User/Response/LoginResponse";

class LoginApi extends Api {
    login(login: LoginRequest): Promise<any> {
        return this.post<LoginRequest>("/login", login);
    }
}

export default new LoginApi();
