




























































































































































import {Component, Vue} from "vue-property-decorator";
import MenuStore from "@/Store/Modules/MenuStore";
import {getModule} from "vuex-module-decorators";
import UserStore from "@/Store/Modules/UserStore";
import userApi from "@/Api/User/UserApi";
import ServerStore from "@/Store/Modules/ServerStore";

@Component
export default class App extends Vue {
    menuStore: MenuStore = getModule(MenuStore, this.$store);
    userStore: UserStore = getModule(UserStore, this.$store);
    serverStore: ServerStore = getModule(ServerStore, this.$store);

    logoutDialog: boolean = false;
    drawer: boolean = true;
    items: object = [
        { title: this.$t('menu.dashboard'), url: '/', icon: 'mdi-view-dashboard', divider: true },
        { title: this.$t('menu.servers'), url: '/servers', icon: 'mdi-server', divider: false },
        { title: this.$t('menu.sites'), url: '/sites', icon: 'mdi-sitemap', divider: false },
        { title: this.$t('menu.applications'), url: '/test', icon: 'mdi-application-outline', divider: true },

        { title: this.$t('menu.support'), url: '/test', icon: 'mdi-face-agent', divider: false }
    ];

    created() {
        if (this.userStore.isAuthenticated) {
            this.userStore.updateUserInfo();
            // this.addLinks();
        }
        if (!this.userStore.isAuthenticated && this.userStore.user !== null) {
            this.userStore.setUser(null);
        }

        // this.$root.$once('loggedIn', this.addLinks);
    }

    mounted() {
        const theme = localStorage.getItem("dark_theme");
        if (theme) {
            if (theme === "true") {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        } else if (
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
            this.$vuetify.theme.dark = true;
            localStorage.setItem(
                "dark_theme",
                this.$vuetify.theme.dark.toString()
            );
        }
    }

    toggleDarkMode() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }

    async logout() {
        await userApi.revokeToken(this.userStore.token as string).then(async () => {
            await this.userStore.logout();
        });
    }
}
