import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
    key: 'applicationStorage',
    modules: ['user']

})

Vue.use(Vuex);
const VueStore = new Vuex.Store({
    plugins: [
        vuexLocal.plugin
    ]
});
const Index = VueStore;

export default VueStore;
export { Index }
