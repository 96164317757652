import Vue from 'vue'
import App from './App.vue'
import vuetify from './Plugins/Vuetify'
import router from "@/Router/Index";
import axios from 'axios';
import store from "@/Store/Index";
import {i18n} from "@/Plugins/i18n";
import Echo from "laravel-echo";
require('./Plugins/Validate');
require('./Plugins/Filters');
require('./Plugins/Echo');

Vue.config.productionTip = false
Vue.config.ignoredElements = [
    'ignore-html',
];

axios.defaults.baseURL = process.env.VUE_APP_API;


new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app')
